import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormButton } from "../../../../../components/FormButton";
import { Input } from "../../../../../components/Input";
import { Loading } from "../../../../../components/Loading";
import { SelectSingle } from "../../../../../components/SelectSingle";
import { api } from "../../../../../services/api";

interface User {
  id: string;
  name: string;
  occupation: string;
  email: string;
  isAdmin: string;
  status: string;
}

interface FormUser {
  name: string;
  email: string;
  newPassword: string;
  status: string;
  isAdmin: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  email: Yup.string().required("Campo obrigatório"),
});

export function UserEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();

  const initialValues = {
    name: user ? user.name : "",
    email: user ? user.email : "",
    newPassword: "",
    status: user ? user.status : "",
    isAdmin: user ? user.isAdmin : "",
  };

  useEffect(() => {
    setLoading(true);
    if (id) {
      api.get(`/users/show/${id}`).then(response => {
        return setUser(response.data);
      });
    }
    return setLoading(false);
  }, []);

  function parseBoolean(field: string) {
    if (field === "true") {
      return true;
    }
    return false;
  }

  function handleSubmit({
    name,
    email,
    newPassword,
    status,
    isAdmin,
  }: FormUser) {
    if (id) {
      api.put(`/users/update/${id}`, {
        name,
        email,
        newPassword,
        occupation: "Usuario",
        status,
        isAdmin: parseBoolean(isAdmin),
      });
    } else {
      api.post("/users", {
        name,
        email,
        newPassword: "1234",
        occupation: "Usuario",
        status,
        isAdmin: parseBoolean(isAdmin),
      });
    }
    setTimeout(() => {
      return navigate("/configuracoes");
    }, 1000);
  }

  return (
    <div className="text-white">
      {loading && <Loading />}
      <div className="my-2 flex items-center justify-between">
        <h1 className="text-xl font-medium">
          {id && user ? `Alterar ${user.name}` : "Novo Usuário"}
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => {
            handleSubmit(values);
            // console.log(values);
          }}
        >
          {({ errors }) => (
            <Form>
              <div className="grid grid-cols-4 gap-2 p-4 bg-[#4a6b26] rounded-lg">
                <div>
                  <Input
                    type="text"
                    title="Nome"
                    name="name"
                    placeholder="Nome"
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    title="E-mail"
                    name="email"
                    placeholder="E-mail"
                  />
                </div>
                <div>
                  <SelectSingle title="Status" name="status">
                    <option value="A">Ativo</option>
                    <option value="I">Inativo</option>
                  </SelectSingle>
                </div>
                {id && (
                  <div>
                    <Input
                      type="password"
                      title="Alterar Senha"
                      name="newPassword"
                      placeholder="Senha"
                    />
                  </div>
                )}
                <div>
                  <SelectSingle
                    title="Permisões de administrador"
                    name="isAdmin"
                  >
                    <option value="false">Não</option>
                    <option value="true">Sim</option>
                  </SelectSingle>
                </div>
              </div>
              <div className="flex gap-2 mt-4">
                <Link
                  to="/configuracoes"
                  className="flex items-center justify-center px-6 py-2 min-h-[56px] text-sm font-medium text-white rounded-lg bg-red-600 drop-shadow-lg shadow-black hover:drop-shadow-xl"
                >
                  <span>Voltar</span>
                </Link>
                <button
                  type="submit"
                  className="px-6 py-1 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#4a6b26] drop-shadow-lg shadow-black hover:drop-shadow-xl"
                >
                  Salvar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
