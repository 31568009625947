/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import GrupoAbertoImg from "../assets/images/GrupoAbertoImg.png";

interface LayoutProps {
  title: string;
  children: JSX.Element;
}

const userNavigation = [
  // { name: "Perfil", href: "/profile" },
  { name: "Sign out", href: "/signin" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Layout({ title, children }: LayoutProps) {
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);

  const navigation = [
    {
      name: "Controle de Entradas e Saídas",
      pathname: "/dashboard",
      current: pathname === "/dashboard",
      show: true,
    },
    {
      name: "Configurações",
      pathname: "/configuracoes",
      current: pathname === "/configuracoes",
      show: user.isAdmin,
    },
  ];

  const userMenu = {
    imageUrl: `https://www.habbo.com.br/habbo-imaging/avatarimage?img_format=png&user=${user.name}&direction=2&head_direction=4&size=l&headonly=1`,
  };
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="">
        <Disclosure as="nav" className="bg-[#4a6b26]">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8"
                        src={GrupoAbertoImg}
                        alt="Workflow"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map(item => (
                          <div className={item.show === true ? "" : "hidden"}>
                            <Link
                              key={item.name}
                              to={item.pathname}
                              className={classNames(
                                item.current
                                  ? "bg-[#39541a] text-white"
                                  : "text-white hover:bg-[#4a6b26] hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium",
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* <button
                        type="button"
                        className="bg-[#4a6b26] p-1 rounded-full text-gray-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#4a6b26] focus:ring-white"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs bg-[#39541a] border-2 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#4a6b26] focus:ring-white">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="h-8 w-8 rounded-full"
                              src={userMenu.imageUrl}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-[#39541a] ring-opacity-5 focus:outline-none">
                            {userNavigation.map(item => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-black",
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-[#4a6b26] inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-[#39541a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#4a6b26] focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map(item => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.pathname}
                      className={classNames(
                        item.current
                          ? "bg-[#39541a] text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium",
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-[#39541a]">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={userMenu.imageUrl}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">
                        {user.name}
                      </div>
                      <div className="text-sm font-medium leading-none text-white">
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map(item => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium bg-[#39541a] hover:bg-red-900 text-white drop-shadow-lg shadow-black hover:drop-shadow-xl transition-colors"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <header className="bg-[#202C19] shadow">
          <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-white">{title}</h1>
          </div>
        </header>
        <main className="">
          <div className="max-w-7xl mx-auto py-6 px-6 lg:px-8">{children}</div>
        </main>
      </div>
      {/* <footer className="p-10 text-sm text-white bg-[#202C19] shadow">
        <div className="grid grid-cols-footer">
          <div className="flex items-center justify-center mr-6">
            <img className="h-8 w-8" src={GrupoAbertoImg} alt="Workflow" />
          </div>
          <div>
            <span className="text-justify">
              Este site não possui vínculos com o Exército Brasileiro, não é
              propriedade ou operado pela Sulake Corporation Oy e não é parte do
              Habbo Hotel®. Desenvolvido por Haouken, The-Mateus. e
              ametista1996.
            </span>
          </div>
        </div>
      </footer> */}
    </>
  );
}
