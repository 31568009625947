import { Routes as Router, Route } from "react-router-dom";
import { PrivateRoute } from "./Route";

import { SignIn } from "../pages/SignIn";
import { Control } from "../pages/Control";
import { Layout } from "../components/Layout";
import { Configurations } from "../pages/Configurations";
import { UserEdit } from "../pages/Configurations/Tabs/Users/UserEdit/UserEdit";
import { Ranking } from "../pages/Ranking";

export function Routes(): JSX.Element {
  return (
    <Router>
      <Route path="/" element={<Ranking />} />
      <Route path="/signin" element={<SignIn />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Layout title="Controle de Entradas e Saídas">
              <Control />
            </Layout>
          </PrivateRoute>
        }
      />

      <Route
        path="/configuracoes"
        element={
          <PrivateRoute>
            <Layout title="Configurações">
              <Configurations />
            </Layout>
          </PrivateRoute>
        }
      />

      <Route
        path="/usuarios/edit/:id"
        element={
          <PrivateRoute>
            <Layout title="Usuário">
              <UserEdit />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/usuarios/new"
        element={
          <PrivateRoute>
            <Layout title="Usuário">
              <UserEdit />
            </Layout>
          </PrivateRoute>
        }
      />
    </Router>
  );
}
