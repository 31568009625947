import axios from "axios";

const PROD = {
  baseURL: "https://deploy.lucasfabriferraro.com",
  // baseURL: "http://54.89.109.115",
};

const DEV = {
  baseURL: "http://localhost:3333",
};

export const api = axios.create({
  baseURL: PROD.baseURL,
});
