import { Field } from "formik";
import { InputHTMLAttributes } from "react";
import { IconBaseProps } from "react-icons";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ComponentType<IconBaseProps>;
  error?: string;
  title?: string;
}

function getClasses(error) {
  return error
    ? {
        div: "w-full min-h-[52px] flex items-center bg-[#39541a] rounded-lg border-2 border-red-600 drop-shadow-lg shadow-black focus:drop-shadow-xl",
        icon: "ml-4 text-red-600",
        field:
          "px-4 w-full h-full text-sm rounded-lg placeholder-red-600 bg-[#39541a] text-red-600 focus:outline-none autofill:bg-[#39541a] focus:ring-0 focus:border-0",
      }
    : {
        div: "w-full min-h-[52px] flex items-center bg-[#39541a] rounded-lg drop-shadow-lg shadow-black focus:drop-shadow-xl",
        icon: "ml-4 text-white",
        field:
          "px-4 w-full h-full text-sm rounded-lg placeholder-white bg-[#39541a] text-white focus:outline-none autofill:bg-[#39541a] border-0 focus:ring-0 focus:border-0",
      };
}

export function Input({ icon: Icon, title, error, ...rest }: InputProps) {
  return (
    <div className="flex flex-col">
      <span className="text-white text-sm my-1">{title}</span>
      <div className={getClasses(error).div}>
        {Icon && <Icon size={24} className={getClasses(error).icon} />}
        <Field className={getClasses(error).field} {...rest} />
      </div>
      <div className="mb-1 text-xs text-red-600">{error}</div>
    </div>
  );
}
