import { XIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { ButtonHTMLAttributes } from "react";
import * as Yup from "yup";
import { FormButton } from "../../../../../components/FormButton";
import { Input } from "../../../../../components/Input";
import { api } from "../../../../../services/api";

interface ModalMilitaryProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  showModal: boolean;
  onHide(): void;
}

interface FormMilitary {
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
});

export function ModalMilitary({ showModal, onHide }: ModalMilitaryProps) {
  const initialValues: FormMilitary = {
    name: "",
  };

  return (
    <div className={!showModal ? "hidden" : "relative"}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#4a6b26] outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold text-white">
                Incluir Militar
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => onHide()}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={values => {
                api.post("/militaries", values);
              }}
            >
              {({ errors, values }) => (
                <Form>
                  <div className="p-4">
                    <Input
                      title="Nome Habbo"
                      name="name"
                      placeholder="Nome Habbo"
                      error={errors.name}
                    />
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-2">
                    <button
                      className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-red-900 drop-shadow-lg shadow-black hover:drop-shadow-xl"
                      type="button"
                      onClick={() => onHide()}
                    >
                      Fechar
                    </button>
                    <FormButton
                      title="Salvar"
                      type="button"
                      onClick={async () => {
                        await api.post("/militaries", values);
                        return onHide();
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}
