/* eslint-disable react/destructuring-assignment */
import { NumericFormat } from "react-number-format";

interface Data {
  topDonations: TopDonations[];
}

interface TopDonations {
  name: string;
  totalDonated: number;
}

export function TopDonations({ topDonations }: Data) {
  return (
    <div className="flex col-span-3 lg:col-span-1 order-4 lg:order-3 flex-col gap-4 w-full">
      <span className="text-xl">Top Doadores</span>
      <div className="flex flex-col p-4 gap-2 rounded-md bg-[#4a6b26]">
        {topDonations &&
          topDonations.map(({ name, totalDonated }: TopDonations) => (
            <a
              href={`https://exbrhb.net/perfil/${name}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center gap-2">
                <img
                  className="h-12 w-12 bg-[#4a6b26] rounded-full border-2 border-white"
                  src={`https://www.habbo.com.br/habbo-imaging/avatarimage?img_format=png&user=${name}&direction=4&head_direction=3&size=m&gesture=sml&headonly=1`}
                  alt=""
                />
                <div className="flex flex-col">
                  <span className="font-medium">{name}</span>
                  <NumericFormat
                    className="text-sm"
                    value={totalDonated}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  />
                </div>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
}
