import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { useAuth } from "../../contexts/AuthContext";
import { Actions } from "./Tabs/Actions";
import { Militaries } from "./Tabs/Militaries";
import { Occupations } from "./Tabs/Occupations";
import { Users } from "./Tabs/Users";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Configurations() {
  const { user } = useAuth();
  const [tab, setTab] = useState({
    component: <Users />,
  });
  const [actualTab, setActualTab] = useState("Usuários");

  const tabs = [
    {
      name: "Usuários",
      component: <Users />,
      show: user.isAdmin,
    },
    {
      name: "Militares",
      component: <Militaries />,
      show: user.isAdmin,
    },
    {
      name: "Ações",
      component: <Actions />,
      show: user.isAdmin,
    },
    // {
    //   name: "Patentes",
    //   component: <Occupations />,
    //   show: user.isAdmin,
    // },
  ];

  return (
    <div className="h-auto">
      <div className="text-sm font-medium text-center text-white border-b border-[#39541a]">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map(({ name, component, show }) => {
            return (
              <li className={show ? "mr-2" : "hidden"}>
                <button
                  type="button"
                  className={classNames(
                    actualTab === name ? "bg-[#4a6b26]" : "hover:bg-[#4a6b26]",
                    "inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:bg-[#4a6b26] hover:border-[#4a6b26]",
                  )}
                  onClick={() => {
                    setTab({
                      component,
                    });
                    setActualTab(name);
                  }}
                >
                  {name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      {tab.component}
    </div>
  );
}
