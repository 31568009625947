/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
// /* eslint-disable import/no-duplicates */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR";
import format from "date-fns/format";
import { addHours, parseISO } from "date-fns";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FiCalendar } from "react-icons/fi";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import Select from "react-select";
import { BsClipboard, BsCoin, BsPlusLg, BsTrash } from "react-icons/bs";
import { HiOutlinePhotograph } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import DatePicker, { registerLocale } from "react-datepicker";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import "react-datepicker/dist/react-datepicker.css";
import { SelectSingle } from "../../components/SelectSingle";
import { DateHourPicker } from "../../components/DateHourPicker";
import { Input } from "../../components/Input";
import { FormButton } from "../../components/FormButton";
import { ModalMilitary } from "../Configurations/Tabs/Militaries/components/ModalMilitary";
import { Loading } from "../../components/Loading";

registerLocale("ptBR", ptBR);

interface Controls {
  id: string;
  military_id: string;
  action_id: string;
  occupation_id: string;
  user_id: string;
  date: string;
  type: string;
  value: number;
  description: string;
  military: Military;
  action: Action;
  occupation: Occupation;
  user: User;
  print: string;
  status: string;
}

interface Military {
  id: string;
  name: string;
}

interface Action {
  id: string;
  name: string;
  type: string;
}

interface Occupation {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
}

interface FormControl {
  military_id: string;
  action_id: string;
  occupation_id: string;
  user_id: string;
  date: Date;
  type: string;
  value: number;
  print: string;
  status: string;
  description: string;
}

const schema = Yup.object().shape({
  military_id: Yup.string().required("Campo obrigatório!"),
  action_id: Yup.string().required("Campo obrigatório!"),
  occupation_id: Yup.string().required("Campo obrigatório!"),
  user_id: Yup.string().required("Campo obrigatório!"),
  date: Yup.date().required("Campo obrigatório!"),
  value: Yup.number().required("Campo obrigatório!"),
  print: Yup.string().required("Campo obrigatório!"),
  status: Yup.string().required("Campo obrigatório!"),
});

interface ReportStatus {
  total: number;
  value_in: number;
  value_out: number;
  balance: number;
}

interface BigNumbers {
  registered: number;
  coins_received: number;
  coins_paid: number;
  balance: number;
  registered_pending: number;
  coins_pending: number;
}

export function Control() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [modalMilitary, showModalMilitary] = useState(false);
  // const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const [startDateForm, setStartDateForm] = useState(
    moment(new Date()).toDate(),
  );
  const [controls, setControls] = useState<Controls[]>();
  const [militaries, setMilitaries] = useState<Military[]>();
  const [actions, setActions] = useState<Action[]>();
  const [occupations, setOccupations] = useState<Occupation[]>();
  const [bigNumbers, setBigNumbers] = useState<BigNumbers>();
  const [newData, setNewData] = useState(0);

  const [militariesOption, setMilitariesOption] = useState([]);
  const [selectMilitaryId, setSelectMilitaryId] = useState<any>("");

  const [militaryId, setMilitaryId] = useState("");
  const [actionId, setActionId] = useState("");
  const [statusId, setStatusId] = useState("A");
  const [occupationId, setOccupationId] = useState("");
  const [beginFilter, setBeginFilter] = useState(
    format(new Date(), "yyyy-MM-dd 00:00:00"),
  );
  const [endFilter, setEndFilter] = useState(
    format(new Date(), "yyyy-MM-dd 23:59:59"),
  );
  const [typeFilter, setTypeFilter] = useState("");
  const [reportStatus, setReportStatus] = useState<ReportStatus>({
    total: 0,
    value_in: 0,
    value_out: 0,
    balance: 0,
  });

  const [open, setOpen] = useState(null);

  function handleOpen(value) {
    setOpen(open === value ? 0 : value);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get("/militaries")
      .then(response => {
        const options = response.data.map(({ id, name }: Military) => {
          return {
            value: id,
            label: name,
          };
        });

        setMilitariesOption(options);

        return setMilitaries(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });

    api
      .get("/actions")
      .then(response => {
        return setActions(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });

    api
      .get("/occupations")
      .then(response => {
        return setOccupations(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });
    setLoading(false);
  }, [modalMilitary]);

  useEffect(() => {
    setLoading(true);
    api
      .get("/controls", {
        params: {
          military_id: militaryId,
          action_id: actionId,
          occupation_id: occupationId,
          type: typeFilter,
          begin: beginFilter,
          end: endFilter,
          status: statusId,
        },
      })
      .then(response => {
        const results = response.data;

        const value_in = results
          .filter(element => element.action.type === "entrada")
          .map(item => item.value)
          .reduce((prev, curr) => prev + curr, 0);

        const value_out = results
          .filter(element => element.action.type === "saida")
          .map(item => item.value)
          .reduce((prev, curr) => prev + curr, 0);

        const balance = value_in - value_out;

        setReportStatus({
          total: results.length,
          value_in,
          value_out,
          balance,
        });

        setControls(results);

        return setLoading(false);
      });

    api.get("/controls/bigNumbers").then(response => {
      return setBigNumbers(response.data);
    });
  }, [
    newData,
    militaryId,
    actionId,
    statusId,
    occupationId,
    beginFilter,
    endFilter,
    typeFilter,
  ]);

  async function handleSubmit({
    action_id,
    date,
    description,
    military_id,
    occupation_id,
    type,
    user_id,
    value,
    print,
    status,
  }: FormControl) {
    setLoading(true);
    await api.post("/controls", {
      action_id,
      date: moment(date).subtract(3, "hours"),
      description,
      military_id,
      occupation_id,
      type,
      user_id,
      value,
      print,
      status,
    });
    setNewData(newData + 1);
    return setLoading(false);
  }

  async function handleDelete(id: string) {
    setLoading(true);
    await api.delete(`/controls/${id}`);

    setNewData(newData - 1);
    return setLoading(false);
  }

  async function handleChangeStatus(id: string) {
    setLoading(true);
    await api.patch(`/controls/status/${id}/A`);

    setNewData(newData - 1);
    return setLoading(false);
  }

  const initialValues: FormControl = {
    military_id: selectMilitaryId,
    action_id: "d8a805bf-a142-47e2-8906-fe5579ec9145",
    occupation_id: "7f27a461-7731-441f-871b-42e424796422",
    // action_id: "ca974fde-e222-4e72-adeb-5e5ea4b8a3ab",
    // occupation_id: "123bfa2a-6053-4d92-96ca-4c2d8111bf45",
    user_id: user.id,
    date: startDateForm,
    type: "",
    value: 2,
    print: "",
    status: "A",
    description: "",
  };

  return (
    <div className="text-white">
      {/* <button type="button" onClick={() => console.log(1)}>
        Initial Values
      </button> */}
      {loading && <Loading />}
      <ModalMilitary
        showModal={modalMilitary}
        onHide={() => showModalMilitary(false)}
      />
      {bigNumbers && (
        <div className="grid grid-cols-4 gap-4 font-medium">
          <div className="bg-[#4a6b26] rounded-lg p-4 gap-y-1 flex flex-col items-center justify-center">
            <h1 className="text-sm md:text-xl text-center">
              Total de registros
            </h1>
            <span className="text-2xl md:text-3xl">
              <NumericFormat
                decimalSeparator=","
                thousandSeparator="."
                displayType="text"
                value={bigNumbers.registered}
              />
            </span>
          </div>
          <div className="bg-[#4a6b26] rounded-lg p-4 gap-y-1 flex flex-col items-center justify-center">
            <h1 className="text-sm md:text-xl text-center">Moedas recebidas</h1>
            <span className="text-2xl md:text-3xl">
              <NumericFormat
                decimalSeparator=","
                thousandSeparator="."
                displayType="text"
                value={bigNumbers.coins_received}
              />
            </span>
          </div>
          <div className="bg-[#4a6b26] rounded-lg p-4 gap-y-1 flex flex-col items-center justify-center">
            <h1 className="text-sm md:text-xl  text-center">Moedas pagas</h1>
            <span className="text-2xl md:text-3xl">
              <NumericFormat
                decimalSeparator=","
                thousandSeparator="."
                displayType="text"
                value={bigNumbers.coins_paid}
              />
            </span>
          </div>
          <div className="bg-[#4a6b26] rounded-lg p-4 gap-y-1 flex flex-col items-center justify-center">
            <h1 className="text-sm md:text-xl text-center">Balanço</h1>
            <span className="text-2xl md:text-3xl">
              <NumericFormat
                decimalSeparator=","
                thousandSeparator="."
                displayType="text"
                value={bigNumbers.balance}
              />
            </span>
          </div>
          <div className="bg-[#4a6b26] rounded-lg p-4 gap-y-1 flex flex-col items-center justify-center">
            <h1 className="text-sm md:text-xl text-center">
              Registros pendentes
            </h1>
            <span className="text-2xl md:text-3xl">
              <NumericFormat
                decimalSeparator=","
                thousandSeparator="."
                displayType="text"
                value={bigNumbers.registered_pending}
              />
            </span>
          </div>
          <div className="bg-[#4a6b26] rounded-lg p-4 gap-y-1 flex flex-col items-center justify-center">
            <h1 className="text-sm md:text-xl text-center">Moedas pendentes</h1>
            <span className="text-2xl md:text-3xl">
              <NumericFormat
                decimalSeparator=","
                thousandSeparator="."
                displayType="text"
                value={bigNumbers.coins_pending}
              />
            </span>
          </div>
        </div>
      )}
      <div className="p-4 mt-4 gap-2 bg-[#4a6b26] rounded-lg">
        <div className="text-sm md:text-xl font-medium">Filtros</div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          <div>
            <span className="text-white text-sm my-1">Datas</span>
            <DatePicker
              className="w-full h-[52px] p-4 rounded-lg bg-[#39541a] focus:outline-none drop-shadow-lg shadow-black focus:drop-shadow-xl border-0 focus:ring-0"
              selectsRange
              startDate={startDate}
              endDate={endDate}
              isClearable
              onChange={update => {
                setDateRange(update);
                const begin = moment(update[0]).format("YYYY-MM-DDT00:00:00");
                const end = moment(update[1]).format("YYYY-MM-DDT23:59:59");

                if (update[0] === null && update[1] === null) {
                  setBeginFilter(null);
                  setEndFilter(null);
                }

                if (begin <= end && update[1] !== null) {
                  setBeginFilter(begin);
                  setEndFilter(end);
                }
              }}
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div>
            <span className="text-white text-sm my-1">Militares</span>
            <select
              className="w-full h-[52px] p-4 rounded-lg bg-[#39541a] focus:outline-none drop-shadow-lg shadow-black focus:drop-shadow-xl border-0 focus:ring-0"
              name="military_id"
              onChange={e => setMilitaryId(e.target.value)}
            >
              <option value="" defaultValue="">
                Todos
              </option>
              {militaries &&
                militaries.map(({ id: military_id, name }: Military) => (
                  <option key={military_id} value={military_id}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <span className="text-white text-sm">Ações</span>
            <select
              className="w-full h-[52px] p-4 rounded-lg bg-[#39541a] focus:outline-none drop-shadow-lg shadow-black focus:drop-shadow-xl border-0 focus:ring-0"
              name="action_id"
              onChange={e => setActionId(e.target.value)}
            >
              <option value="" defaultValue="">
                Todos
              </option>
              {actions &&
                actions.map(({ id: action_id, name }: Action) => (
                  <option key={action_id} value={action_id}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <span className="text-white text-sm">Status</span>
            <select
              className="w-full h-[52px] p-4 rounded-lg bg-[#39541a] focus:outline-none drop-shadow-lg shadow-black focus:drop-shadow-xl border-0 focus:ring-0"
              name="action_id"
              onChange={e => setStatusId(e.target.value)}
            >
              <option value="A" defaultChecked>
                Pago
              </option>
              <option value="P">Pendente</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4 gap-4 grid grid-cols-1 md:grid-cols-2">
        <div className="bg-[#4a6b26] rounded-lg p-4">
          <h1 className="text-xl font-medium">Cadastro de ação</h1>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ errors, setFieldValue }) => (
              <Form>
                <div className="flex flex-col mt-2">
                  <span className="text-white text-sm my-1">Militar</span>
                  <Select
                    className="text-black bg-[#39541a]"
                    name="military_id"
                    options={militariesOption}
                    onChange={selectedOption =>
                      setFieldValue("military_id", selectedOption.value)
                    }
                    // error={errors.military_id}
                  />
                  <button
                    type="button"
                    className="flex items-center justify-center mt-2 h-[52px] w-full bg-[#39541a] rounded-lg shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    onClick={() => showModalMilitary(true)}
                  >
                    <BsPlusLg className="text-white text-lg" />
                  </button>
                  <SelectSingle
                    title="Ação"
                    name="action_id"
                    error={errors.action_id}
                    defaultValue=""
                  >
                    <option value="" defaultChecked disabled>
                      Selecione a ação
                    </option>
                    {actions &&
                      actions.map(({ id: action_id, name }: Action) => (
                        <option key={action_id} value={action_id}>
                          {name}
                        </option>
                      ))}
                  </SelectSingle>
                  <DateHourPicker
                    title="Data e hora"
                    selected={startDateForm}
                    onChange={date => {
                      setStartDateForm(date);
                      setFieldValue("date", format(date, "yyyy-MM-dd HH:mm"));
                    }}
                    timeInputLabel="Time:"
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeInput
                    error={errors.date}
                  />
                  <Input
                    title="Valor"
                    type="number"
                    name="value"
                    placeholder="Valor"
                    error={errors.value}
                  />
                  <SelectSingle
                    title="Status"
                    name="status"
                    error={errors.status}
                    defaultValue=""
                  >
                    <option value="" defaultChecked disabled>
                      Selecione a ação
                    </option>
                    <option value="A">Pago</option>
                    <option value="P">Pendente</option>
                  </SelectSingle>
                  <Input
                    title="Print"
                    type="text"
                    name="print"
                    placeholder="Print"
                    error={errors.print}
                  />
                  <span className="text-white text-sm my-1">Observação</span>
                  <Field
                    as="textarea"
                    className="h-[104px] p-4 w-full rounded-lg text-white bg-[#39541a] focus:outline-none resize-none scrollbar-thumb-[#4a6b26] scrollbar-track-transparent scrollbar-thin drop-shadow-lg shadow-black focus:drop-shadow-xl border-0 focus:ring-0"
                    name="description"
                  />
                  <FormButton title="Incluir" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="bg-[#4a6b26] rounded-lg p-2">
          <h1 className="text-xl font-medium p-2">Ações realizadas</h1>
          <div className="h-[879px] p-3 md:p-4 scrollbar-thumb-[#39541a] scrollbar-track-transparent scrollbar-thin">
            <div className="md:mt-4">
              {controls &&
                controls.map(
                  ({
                    id,
                    date,
                    value,
                    military,
                    // occupation,
                    action,
                    print,
                    // description,
                    status,
                    user: whoPaid,
                  }) => (
                    <div key={id} className="my-1 lg:my-2">
                      <div className="grid grid-cols-custom-2 bg-[#39541a] rounded-lg p-2 lg:p-4 gap-1 lg:gap-2">
                        <div className="flex items-start justify-center">
                          <img
                            className="h-12 w-12 lg:h-16 lg:w-16 bg-[#4a6b26] rounded-full border-2 border-white"
                            src={`https://www.habbo.com.br/habbo-imaging/avatarimage?img_format=png&user=${military.name}&direction=2&head_direction=4&size=l&headonly=1`}
                            alt={military.name}
                          />
                        </div>
                        <div>
                          <div className="w-full flex justify-between">
                            <div className="flex flex-col items-start justify-center">
                              <div className="flex">
                                {action.type === "entrada" ? (
                                  <MdArrowDropUp className="text-2xl text-green-500" />
                                ) : (
                                  <MdArrowDropDown className="text-2xl text-red-600" />
                                )}
                                <span className="font-medium">
                                  {military.name}
                                </span>
                              </div>
                              <div className="flex mb-1">
                                <FiCalendar className="mr-1" />
                                <span className="text-xs">
                                  {format(
                                    addHours(parseISO(date), 3),
                                    "eee dd MMM HH:mm",
                                    {
                                      locale: ptBR,
                                    },
                                  )}
                                </span>
                              </div>
                              <div className="flex flex-wrap lg:flex-nowrap gap-1">
                                <div className="flex">
                                  <BsCoin className="mb-1 lg:mr-1" />
                                  <span className="mx-1 text-xs">
                                    {value} Moedas
                                  </span>
                                </div>
                                <div className="flex">
                                  <BsClipboard className="" />
                                  <span className="ml-1 text-xs">
                                    {action.name}
                                  </span>
                                </div>
                                <div className="flex">
                                  <AiOutlineUser className="" />
                                  <span className="ml-1 text-xs">
                                    {whoPaid.name}
                                  </span>
                                </div>
                                <div className="flex gap-1">
                                  <HiOutlinePhotograph className="" />
                                  <a
                                    className="text-xs"
                                    href={print}
                                    id="link"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Print
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center justify-end">
                              {user && user.isAdmin && (
                                <button
                                  type="button"
                                  className="hover:bg-[#4a6b26] p-2 rounded-lg"
                                  onClick={() => handleDelete(id)}
                                >
                                  <BsTrash />
                                </button>
                              )}
                              {status === "P" && (
                                <button
                                  type="button"
                                  className="hover:bg-[#4a6b26] p-2 rounded-lg"
                                  onClick={() => handleChangeStatus(id)}
                                >
                                  <BsCoin />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
