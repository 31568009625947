import moment from "moment";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { BsCheck2, BsPencil, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import { api } from "../../../../services/api";

interface User {
  id: string;
  name: string;
  occupation: string;
  email: string;
  password: string;
  isAdmin: boolean;
  status: string;
}

function dateFormatter(value: string) {
  return moment(value).format("DD/MM/YYYY");
}

function actionsFormatter(
  cellContent: any,
  row: User,
  rowIndex: number,
  { handleChangeStatus },
) {
  const { id, status } = row;

  return (
    <div className="flex justify-center">
      <button className="ml-1 hover:bg-[#4a6b26] p-2 rounded-lg" type="button">
        <Link to={`/usuarios/edit/${id}`}>
          <BsPencil />
        </Link>
      </button>
      {status === "A" ? (
        <button
          className="ml-1 hover:bg-[#4a6b26] p-2 rounded-lg"
          type="button"
          onClick={() => handleChangeStatus({ id, status: "I" })}
        >
          <BsTrash />
        </button>
      ) : (
        <button
          className="ml-1 hover:bg-[#4a6b26] p-2 rounded-lg"
          type="button"
          onClick={() => handleChangeStatus({ id, status: "A" })}
        >
          <BsCheck2 />
        </button>
      )}
    </div>
  );
}

export function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState(1);

  function handleChangeStatus({ id, status }) {
    setLoading(true);
    api.patch(`/users/${id}/${status}`).then(() => {
      return setChangeStatus(changeStatus + 1);
    });
  }

  useEffect(() => {
    setLoading(true);
    api.get("/users").then(response => {
      setUsers(response.data);
      return setLoading(false);
    });
  }, [changeStatus]);

  const columns = [
    {
      dataField: "name",
      text: "Nick",
      classes: "p-1 border-b w-fit text-left",
      headerClasses: "p-1 w-fit text-left",
    },
    {
      dataField: "status",
      text: "Status",
      classes: "p-1 border-b text-center w-fit capitalize",
      headerClasses: "p-1 w-fit",
    },
    {
      dataField: "isAdmin",
      text: "Administrador",
      classes: "p-1 border-b text-center w-fit capitalize",
      headerClasses: "p-1 w-fit",
    },
    {
      dataField: "created_at",
      text: "Data Criação",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 w-fit",
      formatter: dateFormatter,
    },
    {
      dataField: "updated_at",
      text: "Data Alteração",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 w-fit",
      formatter: dateFormatter,
    },
    {
      dataField: "actions",
      text: "Ações",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 w-fit",
      formatter: actionsFormatter,
      formatExtraData: {
        handleChangeStatus,
      },
    },
  ];

  return (
    <div className="text-white">
      {loading && <Loading />}
      <div className="my-2 flex items-center justify-between">
        <h1 className="text-xl font-medium">Usuários</h1>
        <Link
          to="/usuarios/new"
          className="my-1 p-4 text-sm font-medium text-white rounded-lg bg-[#39541a] drop-shadow-lg shadow-black hover:drop-shadow-xl"
        >
          Novo Usuário
        </Link>
      </div>
      <div className="grid grid-cols-1 gap-4">
        {/* <div className="p-4 bg-[#4a6b26] rounded-lg">
          <div className="text-sm md:text-xl font-medium">Filtros</div>
        </div> */}
        <div className="flex flex-col gap-2 p-4 bg-[#4a6b26] rounded-lg">
          <BootstrapTable
            classes="w-full text-sm"
            bodyClasses=""
            headerClasses=""
            noDataIndication={
              <p className="text-gray-600 font-normal text-base">
                Não foi encontrado nenhum
              </p>
            }
            bootstrap4
            bordered={false}
            remote
            keyField="idMercadoLivreBalancoEnergetico"
            data={users}
            columns={columns}
          />
          {/* {users &&
            users.map(({ id, name, status }) => (
              <div
                key={id}
                className="grid grid-cols-2 bg-[#39541a] rounded-lg p-2 lg:p-4 gap-2 lg:gap-2"
              >
                <div className="flex flex-col items-start justify-center">
                  <div className="flex">
                    <span className="font-medium">{name}</span>
                  </div>
                  <div className="flex flex-col flex-wrap lg:flex-nowrap">
                    <div className="flex items-center mt-1">
                      <span className="ml-1 text-xs">
                        {status === "A" ? "Ativo" : "Inativo"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <Link
                    className="hover:bg-[#4a6b26] p-2 rounded-lg"
                    to={`/usuarios/edit/${id}`}
                  >
                    <BsPencil />
                  </Link>
                  {status === "A" ? (
                    <button
                      className="ml-1 hover:bg-[#4a6b26] p-2 rounded-lg"
                      type="button"
                      onClick={() => handleChangeStatus({ id, status: "I" })}
                    >
                      <BsTrash />
                    </button>
                  ) : (
                    <button
                      className="ml-1 hover:bg-[#4a6b26] p-2 rounded-lg"
                      type="button"
                      onClick={() => handleChangeStatus({ id, status: "A" })}
                    >
                      <BsCheck2 />
                    </button>
                  )}
                </div>
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
}
