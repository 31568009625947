/* eslint-disable react/destructuring-assignment */
import { NumericFormat } from "react-number-format";

interface Data {
  topPayments: TopPayments[];
}

interface TopPayments {
  name: string;
  totalReceived: number;
}

export function TopPayments({ topPayments }: Data) {
  return (
    <div className="flex col-span-3 lg:col-span-1 order-3 lg:order-1 flex-col gap-4 w-full">
      <span className="text-xl">Top Receptores</span>
      <div className="flex flex-col p-4 gap-2 rounded-md bg-[#4a6b26]">
        {topPayments &&
          topPayments.map(({ name, totalReceived }: TopPayments) => (
            <a
              href={`https://exbrhb.net/perfil/${name}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center gap-2">
                <img
                  className="h-12 w-12 bg-[#4a6b26] rounded-full border-2 border-white"
                  src={`https://www.habbo.com.br/habbo-imaging/avatarimage?img_format=png&user=${name}&direction=4&head_direction=3&size=m&gesture=sml&headonly=1`}
                  alt=""
                />
                <div className="flex flex-col">
                  <span className="font-medium">{name}</span>
                  <NumericFormat
                    value={totalReceived}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  />
                </div>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
}
