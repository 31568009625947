import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./contexts/AuthContext";

import { Routes } from "./routes";

export function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Routes />
        </SnackbarProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}
