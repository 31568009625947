export function Loading() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-[#202C19] opacity-75 flex flex-col items-center justify-center">
      <div className="relative w-1/6  bg-gray-200 rounded">
        <div className="w-full absolute top-0 h-4 rounded shim-green" />
      </div>
      <div className="flex gap-2">
        <h2 className="pt-2 text-center text-white text-xl font-semibold">
          Carregando
        </h2>
        <div className="flex items-end mb-1 gap-1">
          <div className="bg-white w-2 h-2 rounded-full animate-bounce" />
          <div className="bg-white w-2 h-2 rounded-full animate-bounce" />
          <div className="bg-white w-2 h-2 rounded-full animate-bounce" />
        </div>
      </div>
    </div>
  );
}
