/* eslint-disable import/no-duplicates */
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

registerLocale("ptBR", ptBR);

function getClasses(error) {
  return error
    ? {
        field:
          "h-[52px] px-4 w-full rounded-lg text-red-600 border-2 border-red-600 bg-[#39541a] focus:outline-none focus:ring-0",
      }
    : {
        field:
          "h-[52px] px-4 w-full rounded-lg text-white bg-[#39541a] focus:outline-none focus:ring-0 border-0",
      };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DateHourPicker({ title, ...props }: any) {
  return (
    <div className="flex flex-col">
      <span className="text-white text-sm my-1">{title}</span>
      <DatePicker className={getClasses(props.error).field} {...props} />
      <div className="text-xs text-red-600">{props.error}</div>
    </div>
  );
}
