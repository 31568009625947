import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  CurrencyDollarIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";

import { NumberFormatValues, NumericFormat } from "react-number-format";
import moment from "moment";
import { Input } from "../../components/Input";
import { api } from "../../services/api";
import { FormButton } from "../../components/FormButton";
import { Loading } from "../../components/Loading";
import { TopPayments } from "./components/TopPayments";
import { TopDonations } from "./components/TopDonations";

const schema = Yup.object().shape({
  name: Yup.string().required("Campo nick obrigatório"),
});

interface Controls {
  name: string;
  totalPending: number;
  totalReceived: number;
}

interface TopDonations {
  name: string;
  totalDonated: number;
}

interface TopPayments {
  name: string;
  totalReceived: number;
}

export function Ranking() {
  const { enqueueSnackbar } = useSnackbar();
  const [controls, setControls] = useState<Controls>();
  const [topDonations, setTopDonations] = useState<TopDonations[]>([]);
  const [topPayments, setTopPayments] = useState<TopPayments[]>([]);
  const [loading, setLoading] = useState(false);
  const year = moment().format("YYYY");

  useEffect(() => {
    api.get("/controls/topDonations").then(response => {
      setTopDonations(response.data);
    });

    api.get("/controls/topPayments").then(response => {
      setTopPayments(response.data);
    });
  }, []);

  async function handleSubmit(name: string) {
    setLoading(true);
    await api
      .get(`/controls/searchByMilitaryName/${name}`)
      .then(response => {
        setControls(response.data);
        setLoading(false);
      })
      .catch(({ response }) => {
        setLoading(false);
        setControls(null);
        enqueueSnackbar(response.data.message, {
          variant: "warning",
        });
      });
  }

  return (
    <>
      {loading && <Loading />}
      <div className="bg-[#4a6b26] text-white p-10">
        <span className="text-xl font-medium">PAGAMENTOS</span>
      </div>
      <div className="flex w-screen p-4 justify-center text-white">
        <div className="grid grid-cols-3 gap-6 w-[900px]">
          <TopPayments topPayments={topPayments} />
          <div className="flex col-span-3 lg:col-span-1 order-1 lg:order-2 flex-col gap-4 w-full">
            <span className="text-xl">Buscar Pagamento</span>
            <div className="flex flex-col p-4 gap-2 rounded-md bg-[#4a6b26]">
              <Formik
                initialValues={{
                  name: "",
                }}
                validationSchema={schema}
                onSubmit={values => handleSubmit(values.name)}
              >
                {({ errors }) => (
                  <Form>
                    <Input
                      type="text"
                      name="name"
                      // icon={MdOutlineMail}
                      placeholder="Nick"
                      autoComplete="off"
                      error={errors.name}
                    />

                    <button
                      className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#32431E] drop-shadow-lg shadow-black hover:drop-shadow-xl hover:-translate-y-1 hover:scale-105 duration-300"
                      type="submit"
                    >
                      Pesquisar
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            {controls && controls !== null ? (
              <div className="flex flex-col bg-[#4a6b26] py-4 gap-2 items-center justify-center rounded-md h-full">
                <span className="text-xl">{controls.name}</span>
                <div className="flex flex-col gap-2">
                  <div>
                    <img
                      className=""
                      src={`https://www.habbo.com.br/habbo-imaging/avatarimage?img_format=png&user=${controls.name}&direction=2&head_direction=2&size=l&action=std`}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-1 items-center">
                      <CurrencyDollarIcon className="h-6 w-6 text-green-500" />
                      <span>{controls.totalReceived} moedas</span>
                    </div>
                    <div className="flex gap-1 items-center">
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-500" />
                      <span>{controls.totalPending} moedas</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center gap-2 bg-[#4a6b26] p-4 rounded-md">
                <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" />
                <span>Militar não encontrado!</span>
              </div>
            )}
          </div>
          <TopDonations topDonations={topDonations} />
          <div className="flex col-span-3 flex-col order-2 lg:order-4 gap-4 w-full">
            <span className="text-xl">COMO FUNCIONAM OS PAGAMENTOS?</span>
            <div className="flex flex-col p-4 gap-2 rounded-md bg-[#4a6b26] text-justify text-sm">
              <span className="text-base font-medium">HORÁRIOS</span>
              <span>12h, 15h, 18h, 21h e 00h.</span>
              <span className="text-base font-medium">REQUISITOS</span>
              <span>Ter as trocas ativadas.</span>
              <span>
                Caso não tenha, será anotado pendência. (explicado em Pagamento
                Pendente)
              </span>
              <span className="text-base font-medium">PATENTES</span>
              <span>- 1c + 2 mobis: Soldados;</span>
              <span>- 1c + 4 mobis: Soldados Estrela;</span>
              <span>- 1c + 6 mobis: Cabos.</span>
              <span>- 2c: Cabo Estrela até Subtenente</span>
              <span className="text-base font-medium">
                REQUISITOS A PARTIR DE CABO ESTRELA
              </span>
              <span>- Realização de um RE completo;</span>
              <span>- Aplicação de um treinamento ou auxílio.</span>
              <span>Qualquer uma das ações acima.</span>
              <span>A ação deve ser feita entre os horários de pagamento.</span>
              <span>
                É necessário que o Praça esteja presente no horário para o
                recebimento do pagamento, caso o mesmo faça a ação e saia, não
                será contabilizado como pendência.
              </span>
              <span className="text-base font-medium">
                HORÁRIOS PARA CONCLUSÃO DA AÇÃO
              </span>
              <span>12h: Conclusão da ação entre 10h até 11h59.</span>
              <span>15h: Conclusão da ação entre 12h até 14h59.</span>
              <span>18h: Conclusão da ação entre 15h até 17h59.</span>
              <span>21h: Conclusão da ação entre 18h até 20h59.</span>
              <span>00h: Conclusão da ação entre 21h até 23h59.</span>
              <span className="text-base font-medium">PAGAMENTO PENDENTE</span>
              <span>
                O pagamento só é anotado como pendente em caso de o Praça
                elegível não possuir trocas ativadas ou então estiver em
                atividade fora do QG no momento do recebimento.
              </span>
              <span>
                O pagamento das pendências só pode ser solicitado à Ex.BR-RE, no
                momento em que um pagamento ocorrer.
              </span>
              <span>
                É anotado como pagamento pendente, caso não possuir um Oficial
                responsável pelos pagamentos no momento da realização do mesmo.
              </span>
              <span>
                O Oficial que estiver no QG, irá repassar o nick de todos os
                Praças elegíveis e será anotado como pendente posteriormente.
              </span>
              <span className="text-base font-medium">PRAÇA DESTAQUE</span>
              <span>
                Definido pelos Oficiais semanalmente, anunciado entre domingo e
                segunda-feira.
              </span>
              <span>- 1° lugar: 25c;</span>
              <span>- 2° lugar: 15c;</span>
              <span>- 3° lugar: 10c.</span>
              <span className="text-base font-medium">RE DESTAQUE</span>
              <span>
                Definido pelos Praças que mais realizarem RE durante a semana,
                contando a partir de 8 ações.
              </span>
              <span>- 1° lugar: 15c;</span>
              <span>- 2° lugar: 10c.</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-[#4a6b26] text-white p-8 gap-4">
        <span className="text-sm">
          Este site não possui vínculos com o Exército Brasileiro, não é de
          propriedade ou operado pela Sulake Corporation Oy e não é parte do
          Habbo Hotel®. Desenvolvido por Haouken.
        </span>
        <span className="text-sm font-medium">
          © 2008 - {year} | Exército Brasileiro Habbo - Rainer
        </span>
      </div>
    </>
  );
}
