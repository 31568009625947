import moment from "moment";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { BsCheck2, BsCoin, BsTrash } from "react-icons/bs";
import { Loading } from "../../../../components/Loading";
import { api } from "../../../../services/api";
import { ModalMilitary } from "./components/ModalMilitary";

interface Military {
  id: string;
  name: string;
  status: string;
}

function dateFormatter(value) {
  return moment(value).format("DD/MM/YYYY");
}

export function Militaries() {
  const [militaries, setMilitaries] = useState<Military[]>([]);
  const [modalMilitary, showModalMilitary] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api.get("/militaries").then(response => {
      setMilitaries(response.data);
      return setLoading(false);
    });
  }, [modalMilitary]);

  const columns = [
    {
      dataField: "name",
      text: "Nick",
      classes: "p-1 border-b w-fit text-left",
      headerClasses: "p-1 w-fit text-left",
    },
    {
      dataField: "status",
      text: "Status",
      classes: "p-1 border-b text-center w-fit capitalize",
      headerClasses: "p-1 w-fit",
    },
    {
      dataField: "created_at",
      text: "Data Criação",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 w-fit",
      formatter: dateFormatter,
    },
    {
      dataField: "updated_at",
      text: "Data Alteração",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 w-fit",
      formatter: dateFormatter,
    },
  ];

  return (
    <>
      <ModalMilitary
        showModal={modalMilitary}
        onHide={() => showModalMilitary(false)}
      />
      <div className="text-white">
        {loading && <Loading />}
        <div className="my-2 flex items-center justify-between">
          <h1 className="text-xl font-medium">Militares</h1>
          <div className="my-1">
            <button
              className="p-4 text-sm font-medium text-white rounded-lg bg-[#39541a] drop-shadow-lg shadow-black hover:drop-shadow-xl"
              type="button"
              onClick={() => showModalMilitary(true)}
            >
              <span>Novo Militar</span>
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="p-4 bg-[#4a6b26] rounded-lg">
            <BootstrapTable
              classes="w-full text-sm"
              bodyClasses=""
              headerClasses=""
              noDataIndication={
                <p className="text-gray-600 font-normal text-base">
                  Não foi encontrado nenhum
                </p>
              }
              bootstrap4
              bordered={false}
              remote
              keyField="idMercadoLivreBalancoEnergetico"
              data={militaries}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
}
