import { Field } from "formik";
import { SelectHTMLAttributes } from "react";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  error?: string;
  title: string;
}

function getClasses(error) {
  return error
    ? {
        field:
          "h-[52px] px-4 rounded-lg text-red-600 border-2 border-red-600 bg-[#39541a] focus:outline-none focus:ring-0",
      }
    : {
        field:
          "h-[52px] px-4 rounded-lg text-white bg-[#39541a] focus:outline-none ring-0 border-0 focus:ring-0",
      };
}

export function SelectSingle({ error, title, children, ...rest }: SelectProps) {
  return (
    <div className="flex flex-col w-full">
      <span className="text-white text-sm my-1">{title}</span>
      <Field as="select" className={getClasses(error).field} {...rest}>
        {children}
      </Field>
      <div className="mb-1 text-xs text-red-600">{error}</div>
    </div>
  );
}
