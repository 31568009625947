import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { BsCheck2, BsTrash } from "react-icons/bs";
import moment from "moment";
import { Loading } from "../../../../components/Loading";
import { api } from "../../../../services/api";
import { ModalAction } from "./components/ModalAction";

interface Action {
  id: string;
  name: string;
  status: string;
}

function dateFormatter(value) {
  return moment(value).format("DD/MM/YYYY");
}

export function Actions() {
  const [actions, setActions] = useState<Action[]>([]);
  const [showModalAction, setShowModalAction] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api.get("/actions").then(response => {
      setActions(response.data);
      return setLoading(false);
    });
  }, [showModalAction]);

  const columns = [
    {
      dataField: "order",
      text: "Ordenação",
      classes: "p-1 border-b text-center w-fit capitalize",
      headerClasses: "p-1 text-center w-fit",
    },
    {
      dataField: "name",
      text: "Ação",
      classes: "p-1 border-b text-left w-fit capitalize",
      headerClasses: "p-1 text-left w-fit",
    },
    {
      dataField: "type",
      text: "Tipo",
      classes: "p-1 border-b text-center w-fit capitalize",
      headerClasses: "p-1 text-center w-fit",
    },
    {
      dataField: "status",
      text: "Status",
      classes: "p-1 border-b text-center w-fit capitalize",
      headerClasses: "p-1 text-center w-fit",
    },
    {
      dataField: "created_at",
      text: "Data Criação",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 text-center w-fit",
      formatter: dateFormatter,
    },
    {
      dataField: "updated_at",
      text: "Data Alteração",
      classes: "p-1 border-b text-center w-fit",
      headerClasses: "p-1 text-center w-fit",
      formatter: dateFormatter,
    },
  ];

  return (
    <div className="text-white">
      {loading && <Loading />}
      <div className="my-2 flex items-center justify-between">
        <h1 className="my-2 text-xl font-medium">Ações</h1>
        <ModalAction
          className="p-4 text-sm font-medium text-white rounded-lg bg-[#39541a] drop-shadow-lg shadow-black hover:drop-shadow-xl"
          showModal={showModalAction}
          setShowModal={setShowModalAction}
        >
          <span>Nova Ação</span>
        </ModalAction>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 bg-[#4a6b26] rounded-lg">
          <BootstrapTable
            classes="w-full text-sm"
            bodyClasses=""
            headerClasses=""
            noDataIndication={
              <p className="text-gray-600 font-normal text-base">
                Não foi encontrado nenhum
              </p>
            }
            bootstrap4
            bordered={false}
            remote
            keyField="idMercadoLivreBalancoEnergetico"
            data={actions}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
}
