import { Form, Formik } from "formik";
import { ButtonHTMLAttributes } from "react";
import * as Yup from "yup";
import { FormButton } from "../../../../../components/FormButton";
import { Input } from "../../../../../components/Input";
import { SelectSingle } from "../../../../../components/SelectSingle";
import { api } from "../../../../../services/api";

interface ModalActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  showModal: boolean;
  children: JSX.Element;
  setShowModal(showModal: boolean): void;
}

interface FormAction {
  name: string;
  type: string;
  description: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  type: Yup.string().required("Campo obrigatório"),
});

export function ModalAction({
  setShowModal,
  showModal,
  children,
  ...rest
}: ModalActionProps) {
  const initialValues: FormAction = {
    name: "",
    type: "",
    description: "",
  };

  return (
    <>
      <div className="my-1">
        <button {...rest} type="button" onClick={() => setShowModal(true)}>
          {children}
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#4a6b26] outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold text-white">
                    Incluir Ação
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={values => {
                    // api.post("/militaries", values);
                  }}
                >
                  {({ errors, values }) => (
                    <Form>
                      <div className="p-4">
                        <Input
                          title="Nome Ação"
                          name="name"
                          placeholder="Nome Ação"
                          error={errors.name}
                        />
                        <SelectSingle
                          title="Tipo"
                          name="type"
                          error={errors.type}
                        >
                          <option value="" defaultChecked disabled>
                            Selecione o tipo
                          </option>
                          <option value="entrada">Entrada</option>
                          <option value="saida">Saída</option>
                        </SelectSingle>
                      </div>
                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b gap-2">
                        <button
                          className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-red-900 drop-shadow-lg shadow-black hover:drop-shadow-xl"
                          type="button"
                          onClick={() => setShowModal(false)}
                        >
                          Fechar
                        </button>
                        <FormButton
                          title="Salvar"
                          type="button"
                          onClick={async () => {
                            await api.post("/actions", values);
                            return setShowModal(false);
                          }}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
}
